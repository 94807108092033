exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articulos-articulo-tsx": () => import("./../../../src/pages/articulos/articulo.tsx" /* webpackChunkName: "component---src-pages-articulos-articulo-tsx" */),
  "component---src-pages-articulos-tsx": () => import("./../../../src/pages/articulos.tsx" /* webpackChunkName: "component---src-pages-articulos-tsx" */),
  "component---src-pages-aviso-privacidad-tsx": () => import("./../../../src/pages/aviso_privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-privacidad-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nuestra-oferta-tsx": () => import("./../../../src/pages/nuestra_oferta.tsx" /* webpackChunkName: "component---src-pages-nuestra-oferta-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes_somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */)
}

